:root {
  --color-primary-dark: hsl(217, 38%, 28%);
  --color-primary: hsl(217, 75%, 33%);
  --color-grey: hsl(217, 5%, 75%);
  --color-light-grey: hsl(217, 5%, 95%);

  --modular-1: 4.736rem;
  --modular-2: 3.553rem;
  --modular-3: 2.665rem;
  --modular-4: 1.999rem;

  --modular-6: 1.125rem;
}
html * {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0 auto;
  max-width: 800px;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

p {
  line-height: var(--modular-4);
  margin-bottom: var(--modular-4);
}

.sr-only {
  position: absoulte !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.link {
  color: var(--color-primary);
}

.hover-link {
  color: inherit;
}

.hover-link--icon:hover {
  color: var(--color-primary);
}

.link:hover,
.hover-link:hover {
  border-bottom: 0.1rem solid var(--color-primary);
}

.button {
  background-color: var(--color-primary-dark);
  padding: 1rem;
  border-radius: 0.4rem;
  color: white;
  border: 0;
}

.button:hover {
  background-color: var(--color-primary);
}

.top-nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.top-nav__link,
.top-nav__button {
  margin-left: 2.5em;
}

.top-nav__link:first-child {
  margin-left: 0;
}

.top-nav__title {
  letter-spacing: 0.2ex;
}

.top-nav__link,
.top-nav__link:visited {
  color: inherit;
}

.top-nav__link:hover {
  border-bottom: 0.2rem solid var(--color-primary);
}

.top-nav__link--active {
  border-bottom: 0.2rem solid black;
}

.top-nav__link--active:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  padding: 1rem 0;
  border-top: solid 1px var(--color-grey);
  font-size: var(--modular-4);
}

.footer__copyright {
  font-size: var(--modular-6);
}

.footer__contacts {
  display: flex;
  flex-flow: row nowrap;
}

.footer__contact {
  margin-right: 1em;
}

.hero {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-top: 20rem;
  margin-bottom: 13rem;
  font-size: var(--modular-4);
}

.hero__header,
.hero__subheader {
  grid-column: span 4;
}

.hero__header {
  font-size: var(--modular-2);
}

.hero__header--index {
  font-size: var(--modular-1);
}

.hero--project {
  margin-top: 12rem;
  margin-bottom: 3rem;
}

.hero__image {
  grid-row: 1;
  grid-column: span 4;
  width: 100%;
}

.project-name {
  font-style: italic;
}

.projects__header {
  font-size: var(--modular-2);
}

.project {
  margin-top: 6rem;
}

.project__screenshot {
  display: block;
  max-height: 40rem;
  max-width: 100%;
  margin: 0 auto;
}

.project__title {
  font-size: var(--modular-3);
  margin-top: 1em;
  color: inherit;
}

.project__technologies {
  font-size: var(--modular-4);
}

.technologies-list {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  max-width: 20ex;
  margin-top: 0.5em;
}

.technologies-list--lg {
  font-size: var(--modular-3);
}

.technology {
  margin-right: 1em;
}

.section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2em;
}

.section--project {
  grid-row-gap: 3em;
}

.section__image-row {
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.section__inline-image {
  grid-column: span 1;
  width: 100%;
}

.section__header {
  font-size: var(--modular-4);
  margin: 1em 0;
}

.section__copy {
  grid-column: span 4;
}

.publication {
  margin-bottom: 1em;
}

.publication__line {
  margin: 0;
}

.lg-contact-icons {
  grid-column: span 4;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: var(--modular-1);
}

.project-details {
  grid-column: span 4;
  margin-bottom: 3rem;
  padding: 1em;
  border-radius: 0.2rem;
  background-color: var(--color-light-grey);
  line-height: var(--modular-4);
}

@media only screen and (min-width: 500px) {
  .section__copy {
    grid-column: 1/4;
  }

  .hero__header,
  .hero__subheader {
    grid-column: 1/4;
  }

  .hero__image {
    grid-column: 2/5;
  }

  .section__image-row {
    grid-column-gap: 6rem;
  }
}

@media only screen and (min-width: 700px) {
  .project-details {
    grid-column: span 3;
  }
}

@media only screen and (min-height: 700px) {
  .hero--index {
    margin-bottom: 28rem;
  }
}
